import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'gatsby';
import { sortExamsByName } from '../../../helpers/sortByExamName';

const ExamsCards = ({ usedFrom, allExams, countryCode }) => {

  const cardItems = (allExams) => {
    let _allExams = allExams.exams;

    if (allExams.otherBatteries.length > 0) {
      _allExams = [..._allExams, {
        title: allExams.otherBatteries[0].fields.title,
        slug: allExams.otherBatteries[0].slug,
        fields: {
          icon: allExams.otherBatteries[0].fields.icon,
        },
      }];
    }

    _allExams = sortExamsByName(_allExams, countryCode);

    return (
      <Row
        justify='center'
        gutter={30}
        className='exam-card-wrapper mt-lg-50 mt-md-20 mt-sm-20 mt-xs-20'
      >
      {
        _allExams.map((item, index) => {
          return (
            <Col lg={6} xs={24} key={index} className='mb-lg-50 mb-md-20 mb-sm-20 mb-xs-20'>
              <Link to={`/examenes/${item.slug}/`}>
                <Row justify='center' className='card-item' align='middle'>
                  <Col lg={22} md={2} sm={4} xs={5}>
                    <Row justify='center' align='middle'>
                      <Col className='icon-wrapper'>
                        <span className={item.fields.icon} title={item.title} />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={24} md={20} sm={18} xs={17}>
                    <p className='mb-0'>{item.title}</p>
                  </Col>
                  <Col className='see-more-wrapper'>
                    <Link to={`/examenes/${item.slug}/`}>
                      Ver más
                    </Link>
                  </Col>
                  <Col xs={2} className='chevron-mobile-wrapper'>
                    <span className='icon-system-chevron-right' />
                  </Col>
                </Row>
                <Row className='orange-bar-desktop' />
              </Link>
            </Col>
          )
        })
      }
      </Row>
    );
  };


  return (
    <Row justify='center' id="examsCards" className='pt-60 pb-40'>
      <Col lg={24} xs={24}>
      {
        usedFrom === 'preocupational' ?
          <h1 className='text-center'>¿Qué examen necesitas?</h1>
        :
          <h4 className='text-center'>¿Qué examen necesitas?</h4>
      }
      <h5 className='text-center'>En Centro Médico del Trabajador contamos con una amplia variedad de baterías de exámenes preocupacionales, cubriendo las necesidades de las principales industrias del país y garantizando que los trabajadores tengan las condiciones físicas y psicológicas necesarias para desarrollar sus actividades laborales.</h5>
      </Col>      
      <Col lg={24} xs={24}>
      {
        allExams && cardItems(allExams)
      }
      </Col>      
    </Row>
  );
};

export default ExamsCards;
