import React from 'react';
import PublicLayout from '../components/PublicLayout';
import SEO from '../components/seo';
import { Layout, Row, Col } from 'antd';
import { graphql } from 'gatsby';
import ExamsCards from './components/preOcupational/examsCards';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;

const PreOcupational = ({ pageContext }) => {
  const { allExams, countryCode } = pageContext;
  const { t } = useTranslation();

  const schemaExtraData = [{
    "@type": "Organization",
    "logo": "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    "sameAs": [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador",
    ],
  }];


  return (
    <PublicLayout>
      <SEO
        title={t('seo.preocupationalOcupationalExams.title')}
        description={t('seo.preocupationalOcupationalExams.description')}
        dataLayer={{
          userId: "",
          pageId: 'exámenes-preocupacionales-ocupacionales',
          pageType: "rubro",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout className='pre-ocupational-page-container'>
        <Content>
          <Row
            justify='center'
            className='mt-lg-90 mt-md-40 mt-sm-40 mt-xs-40 mb-lg-120 mb-md-50 mb-sm-50 mb-xs-50'
          >
            <Col xxl={15} xl={19} lg={19} xs={22}>
              <ExamsCards
                usedFrom='preocupational'
                allExams={allExams}
                countryCode={countryCode}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  )
};

export default PreOcupational;

export const postQuery = graphql`
  query GET_LOCALES_PRE_OCUPATIONAL($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;